import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";


const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
       

      <Slider {...settings} className='dragging mt-10' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://preview.redd.it/no-makeup-sunday-v0-4oaqx130nm2b1.jpg?width=640&crop=smart&auto=webp&s=0d7615a4528b4e4283e2dc7926e7eb91118702af" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Evelyn Bennett</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    Finding myself in the dire straits of having lost $380,000 to a scam, I was initially lured by the false promises of a 
                    phony recovery firm. My ordeal continued for months until, by fate's design, I stumbled upon Integratedanalyst, a 
                    team of truly remarkable professionals. They came to my aid with unparalleled expertise, turning the tide in my favor. 
                    With their skillful intervention, my funds were not just recovered but restored promptly. 
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            </div>

            <div className='pl-2 '>
                <p className='text-[#b0b0b0]'>Texas • USA • 1 week Ago</p>
            </div>
            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'></p>
            </div>
                    
           </div>
        </div>

        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://i.pinimg.com/736x/b2/38/1c/b2381c2e6d2e26b8b8f6185a52d45d1a.jpg" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Jerry Stewart</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    For years, I was in an online relationship with a man named Stephan on Instagram. He promised marriage and, out of love, I 
                    lent him $564,000. He assured me he'd repay, but he abruptly blocked me. Fortunately, I found Integratedanalyst
                    through a recommendation. With minimal effort, I provided them the scammer's details, and they swiftly recovered my 
                    money. I'm immensely grateful to them, The relief of having my hard-earned money back is beyond words.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'> Dakota • USA • 2 days Ago</p>
            </div>
                    
           </div>
        </div>
        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://i.redd.it/1j3d6bstvsz51.jpg" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Fanny Onil</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    I fell victim to a malicious online blackmailer who took control of my personal photos and demanded $50,000 to keep them 
                    private. I felt trapped and helpless until I discovered Integratedanalyst. With their unwavering support, I regained 
                    control of my digital life, and they helped me recover my lost funds. Their expertise, compassion, and dedication turned 
                    my nightmare into a success story, and I'm forever grateful for their remarkable service.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'>Oregon • USA • 6 days Ago</p>
            </div>
                    
           </div>
        </div>


        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGUw7fWH62R1O-_SWbIHLHw4n8qyEdpDCY32j4bCy8Cjt5kGrYJ-sl-gDQtUVJX9HCb-I&usqp=CAU" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Jasper Finnegan</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    I was deceived by a fake investment website that swindled me out of $600,000. I thought my hard-earned money was gone 
                    forever until I found Integratedanalyst. Their dedicated team worked tirelessly to expose the scam and recover my 
                    funds, leaving me with both my money and a renewed sense of financial security. I can't thank them enough for their 
                    unwavering commitment and expertise. They truly are the heroes of my financial recovery journey.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'> Quebec • Canada • 2 weeks Ago</p>
            </div>
                    
           </div>
        </div>
        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://preview.redd.it/4dfz6265xj771.jpg?width=2316&format=pjpg&auto=webp&s=09d3913bdc54a49c1f57f1c6999ec38ce1bb33f8" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Penny Blossom</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    I was locked out of my social media accounts due to a hacking incident that left me feeling violated and helpless. 
                    That's when I turned to Integratedanalyst for help. Their team swiftly restored my accounts and helped me secure 
                    my online presence, providing me with peace of mind. Their expertise in social media recovery gave me back my digital 
                    life, and I couldn't be happier. Thank you, Integratedanalyst, for your exceptional service and for being my lifeline 
                    in the digital world
                    </p>
                </div>
                
            <div className='flex pl-2 pt-2 pb-'>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            <BsStarFill className="text-[#0779e4]"/>
            </div>

            <div className='pl-2 pb-2'>
                <p className='text-[#b0b0b0]'> Victoria • Australia • 5 Hours Ago</p>
            </div>
                    
           </div>
        </div>
       
      
      </Slider>

      <div className='text-center pt-10'>
        <p>swipe to see more.</p>
      </div>
    </div>
  );
}

export default Review;