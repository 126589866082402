import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import Bitcoin from "./Components/Bitcoin";
import Romance from "./Components/Romance";
import Blackmail from "./Components/Blackmail";
import Social from "./Components/Social";
import PhoneUnlock from './Components/PhoneUnlock';
import AccountUnlock from './Components/AccountUnlock';
import About from "./Components/About";
import Contact from "./Components/Contact";

function App() {
  return (
   <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path="/navbar" element={<Navbar/>}/>
    <Route path='/crypto' element={<Bitcoin/>}/>
    <Route path='/romance' element={<Romance/>}/>
    <Route path='/blackmail' element={<Blackmail/>}/>
    <Route path='/social' element={<Social/>}/>
    <Route path='/phoneunlock' element={<PhoneUnlock/>}/>
    <Route path='/accountunlock' element={<AccountUnlock/>}/>
    <Route path='/about' element={<About/>}/>
    <Route path='/contact' element={<Contact/>}/>
   </Routes>
  );
}

export default App;
