import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import { GoDash } from "react-icons/go";
import SocialReview from "./hooks/SocialReview";
import Footer from "./hooks/Footer";

const Bitcoin = () => {
    const CustomeIcon = {
        fontSize: "25px",
        '--custom-icon-color': '#0e6af4'
    }
  return (
    <>

        <div className="box1 overflow-hidden">
            <section class="Social">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                
                <Navbar/>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit 
                relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                    <motion.div className="flex xl:pt-5 pt-10"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                       <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                       <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                    </motion.div>

                    <motion.div className="h-fit"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                        <p className=" font-bold xl:text-[4rem] text-[2rem]">
                        Social Media Hack/Recovery
                        </p>
                    </motion.div>
                </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    
       
        <div className="bg-[#e5e8e8] h-fit">
                <div className="mx-auto overflow-hidden  md:max-w-7xl">
                    <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]">
                        <div className="bg-[reen] h-fit">
                            <div>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}> 
                                    Amidst the expansive digital domain, Integratedanalyst emerge as restoration specialists adept at reclaiming 
                                    elusive accounts. Spanning Instagram, Facebook, TikTok, Twitter, SnapChat and beyond, our unmatched prowess in account 
                                    recovery sets us in a league of our own. Irrespective of the intricate convolutions or multifaceted nuances of 
                                    platforms, we navigate the trajectory to retrieval seamlessly. Our proficient cohort comprehends the inner machinations 
                                    of virtual realms, fortified with sophisticated methodologies to swiftly reinstate your access and resurrect your virtual 
                                    presence.
                                </motion.p> <br/> <br/>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}>
                               Within our service purview, our commitment resonates in furnishing clients with the essential instruments to 
                               unearth veracity and find resolution. Leveraging advanced social media acumen and judicious methodologies, we 
                               surveil suspected dishonest partners or fraudsters. While some may perceive this as "hacking," we deem it 
                               essential to amass evidence judiciously and under proper sanction. Our quintessential objective lies in 
                               conferring tranquility by presenting tangible evidence in a professional and ethical manner.
                                </motion.p>
                            </div>
                        </div>
                        <div className="bg-[reen] h-fit">
                            <motion.div className="flex justify-center "
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.5, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <img src="https://st3.depositphotos.com/4678277/36230/i/450/depositphotos_362309162-stock-photo-portrait-of-positive-cheerful-girl.jpg" alt=""
                                className="rounded-lg img__bord"/>
                            </motion.div>
                        </div>
                    </div>
                </div>
              </div>

              <div className='bg-[#0E6AF4] w-full h-fit p-4 overflow-hidden'>
        <div className='mx-auto md:max-w-4xl overflow-hidden'>
            <div className='grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 overflow-hidden'>
                <div className=''>
                 <div className='flex '>
                 <img src="https://apextechieltd.com/shield.png" alt="" className='w-[64px] '/>
                  <div className='pl-5'>
                    <p className='font-bold text-white pt-3'>Stop worrying, our recovery process is guaranteed.</p>
                  </div>
                 </div>
                </div>
                <div>
                 <div className='xl:ml-[15%] ml-1'>
                   <a href="/contact">
                    <button className='bg-[#1d1829] p-4 text-white btn__touch1'>GET IN TOUCH</button>
                   </a>
                 </div>
                </div>
            </div>
        </div>
    </div>
    <div className='bg-[#1d1829] h-fit'>
             <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Top Testimonies</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[white]'>Our Company's Top Reviews on Social Media Hack & Recovery.</p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0 text-[#b1b1b1]'>
                        Unlocking the Power of Social Media: With billions of users worldwide, social media has revolutionized communication. Integratedanalyst specializes in ethical hacking and recovery services for major platforms like Facebook, WhatsApp, Instagram, and Snapchat, ensuring your digital presence remains secure.
                        </p>
                      </div>
                      </div>
                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden pb-10'>
                  <SocialReview/>
                </div>
            </div>
            <div className=''>
            <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Why Choose Us</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[black]'>Why Choose Integratedanalyst For Social Media Hack & Recovery</p>
                        </div>
                        <div className='pt-5 pr-3 pb-5 xl:pb-0'>
                          <p>Integratedanalyst distinguishes itself as the foremost option for social media hacking and recovery needs. With unparalleled expertise and dedication, we ensure the safety and restoration of your digital presence. Choose Integratedanalyst for trusted and reliable solutions.</p>
                        </div>

                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>All Social Media Hack/Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Text Messages Hack/Spy</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Suspended Social Media Account Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Whatsapp Spy</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Gmail Hack/Spy</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Text Messages Hack/Spy</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Gmail Hack/Spy</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                               
                        </div>
                            
                    </div>
                    <div className='bg-gren-500 h-fit'>
                      <div className='shadow-md xl:mt-10 mt-0'>
                        <img src="https://img.freepik.com/free-photo/high-angle-woman-holding-smartphone_23-2150208257.jpg?t=st=1717638408~exp=1717642008~hmac=286004a2d72302b2dddbae762473aa20eaad1d5920162a352a637e1d2bb7a25d&w=1800" alt=""  className='rounded-md shadow-md img__border'/>
                      </div>
                    </div>
                </div>
            </div>
        </div>


                 <section class="hero">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>



        <div>
            <Footer/>
        </div>

    </>
  )
}

export default Bitcoin;