import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import { GoDash } from "react-icons/go";
import RomanceReview from "./hooks/RomanceReview";
import Footer from "./hooks/Footer";

const Bitcoin = () => {
    const CustomeIcon = {
        fontSize: "25px",
        '--custom-icon-color': '#0e6af4'
    }
  return (
    <>

<div className="box1 overflow-hidden">
            <section class="Romance">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                
                <Navbar/>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit 
                relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                    <motion.div className="flex xl:pt-5 pt-10"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                       <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                       <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                    </motion.div>

                    <motion.div className="h-fit"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                        <p className=" font-bold xl:text-[4rem] text-[2rem]">
                           Romance Scam Recovery
                        </p>
                    </motion.div>
                </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    
        <div className="bg-[#e5e8e8] h-fit">
                <div className="mx-auto overflow-hidden  md:max-w-7xl">
                    <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]">
                        <div className="bg-[reen] h-fit">
                            <div>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}> 
                                    Amidst the complex web of dating scams, where tales of love shroud vanished fortunes, assets, and 
                                    ill-gotten gains, the pursuit of justice takes center stage. In this intricate landscape, we emerge 
                                    as a beacon of hope, steadfast in our mission to reclaim what has been wrongfully acquired. Our 
                                    expertise lies in freeing funds ensnared by the clutches of deceptive schemes. Whether it's monetary 
                                    restitution, property recovery, or the retrieval of stolen loans, we navigate these treacherous waters 
                                    with finesse. Furthermore, we possess a unique proficiency in recuperating funds entangled within the 
                                    intricate networks of fabricated romance profiles, ensuring the triumph of truth over deceit
                                </motion.p> <br/> <br/>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}>
                                Place your trust in us for restitution. Armed with unwavering determination, we employ a diverse range of 
                                strategies and resources, dedicated to recovering what rightfully belongs to you. Together, we will unravel 
                                the intricate webs of deception, restoring not only your financial security but also your faith in humanity.
                                </motion.p>
                            </div>
                        </div>
                        <div className="bg-[reen] h-fit">
                            <motion.div className="flex justify-center "
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.5, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <img src="https://t4.ftcdn.net/jpg/02/42/09/69/360_F_242096970_3YD4aT66okg6Ipz2po5lSe0q8eb2I6xC.jpg" alt=""
                                className="rounded-lg img__bord"/>
                            </motion.div>
                        </div>
                    </div>
                </div>
              </div>

              <div className='bg-[#0E6AF4] w-full h-fit p-4 overflow-hidden'>
        <div className='mx-auto md:max-w-4xl overflow-hidden'>
            <div className='grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 overflow-hidden'>
                <div className=''>
                 <div className='flex '>
                 <img src="https://apextechieltd.com/shield.png" alt="" className='w-[64px] '/>
                  <div className='pl-5'>
                    <p className='font-bold text-white pt-3'>Stop worrying, our recovery process is guaranteed.</p>
                  </div>
                 </div>
                </div>
                <div>
                 <div className='xl:ml-[15%] ml-1'>
                   <a href="/contact">
                    <button className='bg-[#1d1829] p-4 text-white btn__touch1'>GET IN TOUCH</button>
                   </a>
                 </div>
                </div>
            </div>
        </div>
    </div>
    <div className='bg-[#1d1829] h-fit'>
             <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Top Testimonies</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[white]'>Our Company's Top Reviews on Romance Scam Recovery</p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0 text-[#b1b1b1]'>
                        Integratedanalyst has earned accolades for its unparalleled expertise in romance scam recovery. With a commitment to excellence, our team has garnered top reviews from satisfied clients who have benefited from our professional services. Through meticulous consultation and dedicated support, we ensure the complete recovery of funds for victims of romance scams. 
                        </p>
                      </div>
                      </div>
                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden pb-10'>
                  <RomanceReview/>
                </div>
            </div>
            <div className=''>
            <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Why Choose Us</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[black]'>Why Choose Integratedanalyst For Romance Scam Recovery</p>
                        </div>
                        <div className='pt-5 pr-3 pb-5 xl:pb-0'>
                          <p>Integratedanalyst offers unmatched expertise and support for romance scam recovery. With a proven track record of success and top-notch client reviews, we're your reliable partner in regaining control and recovering your funds after falling victim to fraud. Trust Integratedanalyst for expert guidance and comprehensive assistance throughout the recovery process.</p>
                        </div>

                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Loanded Money Recovery </p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Military Romance Scam Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Money Recovery From Romance Scammer</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Properties Recovery</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hook Up Scam Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Fake Broker Investmet Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hacked Bank Account Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hook Up Scam Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hacked Bank Account Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                                
                        </div>
                            
                    </div>
                    <div className='bg-gren-500 h-fit'>
                      <div className='shadow-md xl:mt-10 mt-0'>
                        <img src="https://img.freepik.com/free-photo/portrait-focused-african-american-programer-wearing-wireless-headphones-working-looking-computer-screen-while-typing-software-developer-writing-code-it-agency-office_482257-43992.jpg?t=st=1717559618~exp=1717563218~hmac=c2002ed25e8d8ae35e4cc70cf6a9f9512f10dd2bf9ede3ce6c063a2397e2f9a3&w=2000" alt=""  className='rounded-md shadow-md img__border'/>
                      </div>
                    </div>
                </div>
            </div>
        </div>


                 <section class="hero">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>



        <div>
            <Footer/>
        </div>

    </>
  )
}

export default Bitcoin;