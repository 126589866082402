import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; 
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: 'yellow',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 3,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5'>
      <SwiperSlide>
        <div className='border__b  mb-5 rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
              <img src='https://i.pinimg.com/1200x/55/29/03/55290328c23fccf2257ab0844c6f71bf.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Maximilian Beauregard</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className='px-2'>
            <p className='text-[whitesmoke] text-center pt-[10%] pb-5 '>
            I had a terrible experience with a fake recovery firm that promised to restore my stolen Bitcoin but ended up taking more money from me. Thankfully, I crossed paths with Integratedanalyst, and they came to my rescue.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-3'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400 pt-5'>Tallahassee, FL USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md '>
          <div className='flex justify-center pt-5 mb-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
              <img src='https://preview.redd.it/started-cleaning-my-room-but-ended-up-taking-selfies-v0-ejz69dvz18nb1.jpg?width=640&crop=smart&auto=webp&s=bde9b4e664f038c04072e1e054e39a07af308b55' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
          <p className="text-center font-bold pt-3 text-[#c0c2cd]">Seraphina Thistlewood</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className=''>
            <p className='text-[whitesmoke] pt-5 text-center  pb-5'>
            I mistakenly sent $60,000 worth of Bitcoin to the wrong wallet, and I thought I had lost it forever. A friend recommended Integratedanalyst, and they worked their magic. With ease, they restored my money. These experts are the best in the business, and I'm immensely grateful.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
            <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400'>Tampa, FL USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
              <img src='https://i.pinimg.com/564x/e1/3a/4b/e13a4b08619959fdf9dc732df6fae3d6.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Calista Sinclair</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className='p-1'>
            <p className='text-[whitesmoke] text-center pt-2 pb-2'>
            I had been investing with a fake broker site for months, only to discover it was a scam that had swindled me out of $100,000. In my despair, I contacted Integratedanalyst, and they didn't waste a moment. They swiftly restored my money, sparing me any further agony. Their efficiency is unmatched.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[9%]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400'>Alpharetta, ATL USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
              <img src='https://i.pinimg.com/1200x/99/7e/fd/997efd72a41863bc06386d87bd408ca5.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
          <p className="text-center font-bold pt-3 text-[#c0c2cd]">Thaddeus Wainwright</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className='py-1'>
            <p className='text-[whitesmoke] text-center pt-[5%] pb-5'>
            After falling victim to a Bitcoin scam and losing $150,000, I was left devastated and in disbelief. It seemed like all hope was lost until I saw their recommendations online and decided to contact Integratedanalyst. Their team of professionals worked tirelessly to recover my lost funds. 
               </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[5%]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400 pt-[1%]'>Wawa Ontario, Canada 🇨🇦</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
              <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCuDyRTQxCc5gtQOJCOWq1NHSUCNrZKhYgQ9GT9Yxv4ihhd7BFhbAnSWjCIrdOZe4CdL0&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
          <p className="text-center font-bold pt-3 text-[#c0c2cd]">Calista Nightshade</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[whitesmoke] text-center pt-5 pb-2'>
            Being a victim of a cryptocurrency scam was a nightmare. I thought I'd never see my hard-earned Bitcoin again. However, Integratedanalyst proved me wrong. They not only restored my Bitcoin but also provided incredible support throughout the process. I'm forever grateful for their dedication and expertise.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400'>Memphis, USA  🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
     
    </Swiper>
  );
};

export default MySwiper;
