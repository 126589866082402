import React from 'react';
import Navbar from "./Navbar";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import { useRef, useEffect } from 'react';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import Review from "./hooks/Review";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Footer from "./hooks/Footer";
import Tooltip from '@mui/material/Tooltip';
import { GoDash } from "react-icons/go";


const Home = () => {
    const AnimatedNumbers = ({ value }) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, { duration: 6000 });
        const isInview = useInView(ref);
    
        useEffect(() => {
            if (isInview) {
                motionValue.set(value);
            }
        }, [isInview, value, motionValue]);
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if (ref.current && latest.toFixed(0) <= value) {
                    ref.current.textContent = parseInt(latest).toLocaleString(); // Add this line to format the value with commas
                }
            });
        }, [springValue, value]);
    
        return <span ref={ref}></span>;
    };
  return (
    <>
    <div className="box1 overflow-hidden">
            <section class="herox">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-0 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                   
                        <div className="pl-2 relative">
                          
                        </div>
                </motion.div>
                <Navbar/>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.3, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white'>Your Reliable Asset Recovery Partner!</p>
                                </motion.div>

                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[2.5rem] font-bold text-white'>Expert Team & Cutting-Edge<br/>Technology for Swift Retrieval</p>
                                </motion.div>

                                <motion.div className='pt-2'
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white'>
                                    Specializing in expert asset recovery services for both individuals and businesses, 
                                    we assure you of swift and seamless asset retrieval.
                                    </p>
                                </motion.div>

                                <div className='bg-[reen] h-20 flex gap-1 mr-1 mt-5'>
                                   <a href="/Contact"><button className='get__one'>Get Started <i class="ri-arrow-right-line"></i></button></a>
                                    <a href="/Contact"><button className='get__two'>Report Fraud <i class="ri-arrow-right-line"></i></button></a>
                                </div>
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>


        <div className='bg-[#f7f7f8] h-fit'>
            <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-reen-600 h-fit'>
                            <div>
                                <p className='text-[#0779e4]'>Our Top Policy</p>
                            </div>
                            <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.4, duration: 0.5 }}
                            variants={{
                                hidden: { opacity: 0, x: -50 },
                                visible: { opacity: 1, x: 0 },
                            }}>
                                <p className='font-bold text-[1.8rem]'>Our Company is Trusted by Thousands of <span className='span_x'>Customers</span></p>
                            </motion.div>

                            <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.3, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <p className='pt-5'>
                                 Our unwavering commitment to excellence, upheld by a team of dedicated professionals, has consistently earned 
                                the trust of thousands of customers who rely on our quality services.
                                </p>
                            </motion.div>

                            <motion.div className='pt-5'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.4, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <div className='flex'>
                                    <BsArrowRight size={25} className='text-[#0779e4]'/>
                                    <span className='pl-3 font-bold'>Accuracy</span>
                                </div>

                                <div>
                                    <p className='pt-2'>
                                    Discover the excellence of our versatile and dynamic team, proficient in various fields. 
                                    Witness our seamless collaboration in achieving impeccable results, leaving no room for error.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div className='pt-5'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.5, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <div className='flex'>
                                    <BsArrowRight size={25} className='text-[#0779e4]'/>
                                    <span className='pl-3 font-bold'>Refund Policy</span>
                                </div>

                                <div>
                                    <p className='pt-2'>
                                        With a wealth of expertise at our disposal, we maintain a high success rate, minimizing refund requests. 
                                        If you're unsatisfied, rest assured that we offer a swift and hassle-free refund process.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div className='pt-5'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.6, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <div className='flex'>
                                    <BsArrowRight size={25} className='text-[#0779e4]'/>
                                    <span className='pl-3 font-bold'>Privacy Policy</span>
                                </div>

                                <div>
                                    <p className='pt-2'>
                                    Detailing our commitment to safeguarding clients' personal 
                                    and financial information, our policy adheres to all pertinent data protection regulations.
                                    </p>
                                </div>
                            </motion.div>
                    </div>
                    <div className='bg-green-00 h-fit'>
                        <motion.div className='flex justify-center'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.4, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                            <img src="https://moneyrevoke.vercel.app/home-about.jpg.webp" alt="" className=''/>
                        </motion.div>
                    </div>
                </div>
            </div>



            <section class="numbers">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.2, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="https://moneyrevoke.vercel.app/currency.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            $<AnimatedNumbers value={30}/>m+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Money Restored
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.4, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="https://moneyrevoke.vercel.app/rating-stars.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={11000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Satisfied Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.6, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="https://moneyrevoke.vercel.app/trusto.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={10000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Trusted 
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.7, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="https://moneyrevoke.vercel.app/security.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={7000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Protected Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                  </div>
               </div>
            </div>
            </section>



            <div className='bg-[#1d1829] h-fit'>
              <div className='mx-auto md:max-w-7xl overflow-hidden'> 
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Key Services</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[white]'>Our Company's Main Service Spectrum</p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0 text-[#b1b1b1]'>
                        At integratedanalyst we specialize in recovering funds lost to scams. Our team of experts is dedicated to restoring financial losses incurred through fraudulent activities. We offer a range of services tailored to meet the unique needs of our clients, ensuring efficient and effective recovery processes.
                        </p>
                    </div>
                </div>


                <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="https://apextechieltd.com/bitcoina.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Crypto Currency Recovery</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                                Have you ever felt the frustration of lost funds or cryptocurrency slipping through your grasp? Worry not, for we're here to guide you through the intricate path of their recovery. Rest assured, with our expertise, you can regain access to your locked-out wallet. Your journey is made smoother with the adept assistance, led by integratedanalyst and their dedicated team of professionals.
                            </p>
                        </div>

                        <div>
                        <Tooltip title="Crypto Currency Recovery" arrow>
                          <a href="/crypto">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-full shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="https://apextechieltd.com/heartx.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Romance Scam Recovery</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            Assisting Those Affected by Romance Scams, we're committed to helping you overcome the distress caused by these deceitful actions. With our focused experts, we're determined to rebuild your confidence and guide you through the recovery of your lost assets. Trust us to lead you towards recovery with unwavering commitment. 
                            </p>
                        </div>

                        <div className='mt-5'>
                        <Tooltip title="Romance Scam Recovery" arrow>
                          <a href="/romance">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="https://apextechieltd.com/thief.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Blackmail / Extortion Help</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            Empowering and Advocating for Victims of Online Blackmail, Sextortion, and Threats. Our dedicated mission is to provide unwavering support and compassionate assistance to individuals who have unfortunately encountered these distressing experiences. We steadfastly stand by their side, offering expert guidance to those who have fallen prey to online threats and malicious intent.
                            </p>
                        </div>

                        <div>
                        <Tooltip title="Blackmail / Extortion Help" arrow>
                          <a href="/blackmail">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-full shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.5, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="https://apextechieltd.com/watch.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Social Media Spy/Recovery</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            Empowering Your Digital Connections: Social accounts act as crucial links to numerous achievements and cherished connections. Recognizing their importance, we stand unwavering in our dedication to surpass expectations, leaving no stone unturned in ensuring your swift account recovery. Trust in our unwavering expertise and commitment to promptly reinstate your access
                            </p>
                        </div>

                        <div>
                        <Tooltip title="Social Media Spy/Recovery" arrow>
                          <a href="/social">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="https://apextechieltd.com/output-onlinegiftools.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Phone Hack & unlock</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            integratedanalyst Comprising a team of esteemed specialists in phone unlocking, rest assured, no detail escapes our scrutiny. Our meticulous expertise and unwavering commitment guarantee thoroughness in each phase, delivering definitive solutions tailored to your requirements. Place your trust in us for an unparalleled, hassle-free experience & Our mission is to safeguard your digital assets.
                            </p>
                        </div>

                        <div>
                        <Tooltip title="Phone Hack & unlock" arrow>
                          <a href="/phoneunlock">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-full shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.7, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="https://apextechieltd.com/look.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Account Unlock & Recovery</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            Unlock the potential to regain access to your locked accounts with our tailored tools. Experience the convenience of our solutions designed to overcome account lockouts effortlessly. At  integratedanalyst, we specialize in swift account recovery, providing you with the means to regain control and peace of mind for your digital assets.
                            </p>
                        </div>

                        <div className='mt-3'>
                        <Tooltip title="Account Unlock & Recovery" arrow>
                          <a href="/accountunlock">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                  </div>
                </div>
            </div>
            </div>


        
        <div className='bg-green-00 mt-10 xl:ml-[10%] ml-3 overflow-hidden  md:max-w-2xl'>
            <div className='pt-10'>
                <p className='text-[#0779e4]'>Core Value</p>
            </div>

            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
                }}
            >
                <p className='font-bold text-[1.8rem]'>Why<span className='span_x'> Choose Us</span></p>
            </motion.div>
        </div>



        <div className="wavex">
                <div className="mx-auto overflow-hidden  md:max-w-7xl">
                    <div className='grid mt-10 xl:mt-0 ml-1 mr-1 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-3 pb-10 pt-[5%]'>
                            <motion.div className="bg-green-00 h-fit serv__box rounded-md bg-[white]"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.3, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <div className='pl-5 pt-4'>
                                    <img src="https://moneyrevoke.vercel.app/thinking.png" alt="" className="w-[64px]" />
                                </div>

                                <div className='pl-5 pt-3 pr-2 pb-4'>
                                    <div>
                                    <span className='font-bold'>Experience Matters</span>
                                    </div>
                                    <p>
                                    With a wealth of expertise accumulated over many years, we're highly skilled at successfully navigating even 
                                    the most intricate and complex recovery scenarios. Our extensive experience uniquely positions us to tackle 
                                    the challenges associated with asset recovery, ensuring that you receive the best possible solutions and 
                                    outcomes.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div className="bg-green-00 h-fit serv__box xl:mt-10 mt-0 bg-[#0779e4] rounded-md"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.6, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <div className='pl-5 pt-4'>
                                    <img src="https://moneyrevoke.vercel.app/loveox.png" alt="" className='w-[64px]'/>
                                </div>

                                <div className='pl-5 pt-3 pr-2 pb-4'>
                                    <div>
                                    <span className='font-bold text-white'>Compassionate Support</span>
                                    </div>
                                    <p className='text-white'>
                                    Our dedicated and empathetic team is always here for you, providing not only their expertise but also 
                                    genuine understanding and care. We believe in the power of empathy to complement our expertise, ensuring 
                                    that you receive not just solutions, but also the emotional support you need during challenging times. 
                                    Your well-being is our priority, and we're committed to offering a reassuring presence throughout your 
                                    journey toward recovery and security.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div className="bg-green-00 h-fit serv__box rounded-md bg-white"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.8, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <div className="pl-5 pt-4">
                                    <img src="https://moneyrevoke.vercel.app/goal.png" alt="" className="w-[64px]"/>
                                </div>

                                <div className='pl-5 pt-3 pr-2 pb-4'>
                                    <div>
                                    <span className='font-bold'>Proven Success</span>
                                    </div>
                                    <p>
                                    Our well-established track record, spanning over years of dedicated service, clearly demonstrates our 
                                    ability to consistently deliver tangible and measurable results. With a history of successful outcomes 
                                    and a strong foundation of trust that our clients place in us, we stand as a testament to our unwavering 
                                    commitment to excellence. 
                                    </p>
                                </div>
                            </motion.div>
                    </div>
                </div>
        </div>

      
        <div className='bg-green-00 mt-10 xl:ml-[10%] ml-3 overflow-hidden  md:max-w-2xl' id="Reviews">
            <div className='pt-10'>
                <p className='text-[#0779e4]'>Reviews</p>
            </div>

            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
                }}
            >
                <p className='font-bold text-[1.8rem]'>Top<span className='span_x'> Testimonies</span></p>
            </motion.div>
        </div>



        <div className="mx-auto overflow-hidden  md:max-w-7xl">
            <Review/>
        </div>



        
        <div className='bg-green-00 mt-10 xl:ml-[10%] ml-3 overflow-hidden  md:max-w-2xl' id='FAQ'>
            <div className='pt-10'>
                <p className='text-[#0779e4]'> F .A. Q</p>
            </div>

            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
                }}
            >
                <p className='font-bold text-[1.8rem]'>Frequently Ask <span className='span_x'> Questions</span></p>
            </motion.div>
        </div>

        <div className="max-w-md mx-auto  overflow-hidden md:max-w-6xl mt-[5%] mb-[5%] pl-3 pr-2">
                        <div className=" grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5">
                            <div className="bg-[f60505] h-fit overflow-hidden">
                            <Accordion className="">
                                <AccordionSummary expandIcon={<i class="ri-arrow-down-s-line text-[1.4rem]"></i>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <p className="text-[#030d26] font-semibold">Refund Policy</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    We are committed to delivering high-quality results and meeting our clients'
                                    expectations. While we are confident in our ability to complete the task at
                                    hand, we understand that unforeseen circumstances can sometimes arise that
                                    may impact our ability to deliver on our promises. In the event that we are
                                    unable to complete the task, we offer a refund as part of our commitment to
                                    ensuring our clients' satisfaction and building trust in our brand. We believe
                                    in transparency and fairness in all our dealings, and our terms and conditions
                                    are designed to provide clarity and protection to both our clients and our team.
                                    We are dedicated to providing exceptional service and value to our clients, and 
                                    we are always striving to improve our processes and exceed expectations.
                                </AccordionDetails>
                            </Accordion>    

                                <div className="pt-5">
                                    <Accordion className="">
                                        <AccordionSummary expandIcon={<i class="ri-arrow-down-s-line text-[1.4rem]"></i>}
                                             aria-controls="panel1a-content"  id="panel1a-header" >
                                            <p className="text-[#030d26] font-semibold">Can I trust you?</p>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                           We recognize that the topics of trust and authorithy are critical considerations that must be addressed before 
                                           our clients can feel confident and secure in sharing before our clients can feel confident and secure in sharing
                                           sensitive financial information with our team. We understand that the decision to entrust us with such information
                                           is one that requires careful consideration,and we take this responsibility seriously. We believe that trust is earned
                                           through consistent and reliable performance, and we strive to exceed our clients expectations in every aspect of your work.
                                        </AccordionDetails>
                                    </Accordion>    
                                </div>
                            </div>

                            <div className="bg-[f60505] h-fit">
                                <div className="">
                                    <Accordion className="">
                                        <AccordionSummary expandIcon={<i class="ri-arrow-down-s-line text-[1.4rem]"></i>} aria-controls="panel1a-content" id="panel1a-header">
                                            <p className="text-[#030d26] font-semibold">How does recovery work?</p>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        At Integratedanalyst, we understand that each account recovery case is unique and requires a tailboard approach. 
                                        We use a variety of techniques to recover our clients' accounts, depending on the specifics of each case. <br/> <br/>

                                        In the event of password recovery, we work closely with our clients to extract an encrypted private 
                                        key backup and build a comprehensive list of possible password guesses. We then use advanced 
                                        algorithms and software to try billions or even trillions of possible password combinations until 
                                        the correct password is found.
                                        </AccordionDetails>
                                    </Accordion>    
                                </div>

                                <div className="pt-5">
                                    <Accordion className="">
                                        <AccordionSummary expandIcon={<i class="ri-arrow-down-s-line text-[1.4rem]"></i>}
                                             aria-controls="panel1a-content"  id="panel1a-header" >
                                            <p className="text-[#030d26] font-semibold">How long does the process take?</p>
                                        </AccordionSummary>
                                            <AccordionDetails>
                                           The Process of recovering lost Cryptocurrency can vary in duration, ranging from as brief as one hour
                                           to as long as 2 months or more months. Although this may seem like a lengthy period, it is because we typically 
                                           conduct an extensive search within the first week of on boarding a client. Once this initial search has been 
                                           exhausted, we retain the encrypted private backup and password guesses to continue testing as we identify new 
                                           recovery vectors and expand our handware capabilities. We only delete clinet information upon request, as we 
                                           patronize our clients privacy and secirity.
                                           </AccordionDetails>
                                    </Accordion>    
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <section class="hero">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>


        <div>
            <Footer/>
        </div>

    </div>

        
    </> 
  )
}

export default Home